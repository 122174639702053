














































import { Component, Prop, Vue } from 'vue-property-decorator';

import Logo from "@/components/Logo.vue";
import ImagePanel from '@/components/ImagePanel.vue';

import { ISubscriptionTier } from '@/interfaces/ISubscriptionTier';

import { KnoxService } from '@/services/knox.service';

import { StripeCheckout } from 'vue-stripe-checkout';


@Component({
  components: {
    Logo,
    ImagePanel,
    StripeCheckout
  }
})
export default class SubscriptionCheckout extends Vue {
  @Prop({}) tier!: any;

  loading = true;

  pk = '';
  session = '';

  async _handleCheckout() {
    
  }

  get parsedTier() {
    return JSON.parse(this.tier);
  }

  get successUrl() {
    return `https://${window.location.host}/subscribe-success?session_id={CHECKOUT_SESSION_ID}`
  }

  get cancelUrl() {
    return `https://${window.location.host}/tiers`
  }

  async load() {
    const customer = this.$store.state.user.profile.stripeCustomer;
    const { sessionId } = await KnoxService.createSubscriptionCheckoutSession('core', customer);

    this.session = sessionId;
  }

  mounted() {
    this.pk = process.env.VUE_APP_STRIPEPK
    this.load();
  }
}

